import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    text: {
        alignSelf: 'center',
        fontSize: 16,
        lineHeight: 24,
        fontWeight: '700',
        color: COLORS.COLOR_NEUTRAL_GRAY,
    },
    icon: {
        width: 20,
        height: 20,
        marginHorizontal: 7,
    },
    button: {
        borderRadius: 8,
        paddingHorizontal: 10,
        height: 44,
        display: 'flex',
        backgroundColor: 'transparent',
    },
    disable: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: COLORS.COLOR_BTNGRAY,
    },
    innerView: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1,
    },
    gradient: {
        flex: 1,
        borderRadius: 8,
        width: null,
        height: null,
    },
})
export default styles
